/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Slideshow } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"muzeum-meteoritu.cz - Podpora nového muzea"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-zop4a2 js-anim  --anim3 --anim-s3 --center --parallax" style={{"paddingTop":158,"marginBottom":0,"paddingBottom":302}} anim={"3"} name={"uvod"} animS={"3"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37955/5c61219c22d744eab3a74c2a6e60aac0_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37955/5c61219c22d744eab3a74c2a6e60aac0_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37955/5c61219c22d744eab3a74c2a6e60aac0_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37955/5c61219c22d744eab3a74c2a6e60aac0_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37955/5c61219c22d744eab3a74c2a6e60aac0_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37955/5c61219c22d744eab3a74c2a6e60aac0_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37955/5c61219c22d744eab3a74c2a6e60aac0_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37955/5c61219c22d744eab3a74c2a6e60aac0_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":900}} anim={"2"} animS={"3"}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ds8g8wg55d"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--26" content={"<span style=\"font-weight: bold; color: rgb(169, 12, 225);\">NÁŠ SEN SE STÁVÁ SKUTEČNOSTÍ</span>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":650}} content={"<span style=\"font-weight: bold;\">Po více než 3. letech jsme na naší cestě zase o kus dál.\n<br><br>&nbsp;Bude to těžké, bude to finančně nákladné, ale my se moc těšíme, až vás přivítáme v novém prostoru.\n\nNavíc co by meteoritem dohodil od původního muzea. <br><br><span style=\"color: rgb(175, 18, 231);\">&nbsp;Co můžete v novém muzeu očekávat?\n</span><br><br>&nbsp;- Stovky exponátů\n\n\n- Samozřejmě samotnou expozici meteoritů, tektitů a dalších kamenů, která se i díky našim partnerům rozšíří o mimořádné kousky.\n<br><br>&nbsp;- Mnoho informací o meteoritech, kde se berou, jak je najít a poznat, nebo jaké nebezpečí přinášejí.\n\nNěkteré meteority si také potěžkáte!\n<br><br>&nbsp;- Náš vesmírný shop\n<br><br>&nbsp;- Galerie s úžasnými fotografiemi vesmíru a vesmírných jevů\n<br><br>&nbsp;- Knihovna\n\n\n- Místo, kde si můžeš v příjemném prostředí posedět u dobré kávy nebo čaje\n<br>&nbsp;U toho můžeš číst, sledovat dokumenty, nebo jednoduše relaxovat\n<br><br>&nbsp;- Projekce dokumentů a vzdělávacích pořadů\n<br>&nbsp;Vzdělávací přednášky pro školy, školky, seniory a další skupiny\n<br><br>&nbsp;- Přednášky zajímavých hostů\n<br><br>&nbsp;- Speciální akce pro děti i dospělé\n<br><br>&nbsp;A mnoho dalšího!</span>"}>
              </Text>

              <Image style={{"maxWidth":547}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/9431e3d7f5424b5b92957cfdb1e21105_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/9431e3d7f5424b5b92957cfdb1e21105_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/9431e3d7f5424b5b92957cfdb1e21105_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/9431e3d7f5424b5b92957cfdb1e21105_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37955/9431e3d7f5424b5b92957cfdb1e21105_s=2000x_.jpg 2000w"} alt={""} src={"https://cdn.swbpg.com/t/37955/9431e3d7f5424b5b92957cfdb1e21105_s=860x_.jpg"}>
              </Image>

              <Text className="text-box fs--14" content={"<span style=\"font-weight: bold;\">Původní muzeum otevřeno do 23.12.2024 !\n<br>&nbsp;Po-Pá 9-16 hod.\n<br><br>&nbsp;Do vánoc, přesněji do 23.12. nás ještě můžete navštívit na starém muzeu.\n<br>&nbsp;Poté zavřeme, abychom stihli na jaře otevřít nové, největší muzeum meteoritů v ČR a SK!</span>"}>
              </Text>

              <Text className="text-box fs--14" style={{"maxWidth":564}} content={"<span style=\"font-weight: bold;\"><br><span style=\"color: rgb(59, 238, 17);\">Chceš nám také pomoci?\n<br></span><br>&nbsp;Vytvořit nové muzeum bude náročný úkol, ale my víme, že to zvládneme.\n<br><br>&nbsp;A protože víme, jak skvělé máme příznivce, přidáváme možnosti, jak nám můžete pomoci, pokud budete chtít:<br><br>&nbsp;- Pro místní a taky z blízkého okolí. Poslední týden v lednu budeme stěhovat. <br>Rádi uvítáme každou ruku a nohu, která nám bude chtít pomoci.\n<br><br>&nbsp;- Rozšiřujeme sbírku. Chceš nám zapůjčit, nebo dokonce darovat nový exponát?\n<br>Uvítáme meteority, tektity, zajímavé vesmírné artefakty, knihy, dokumenty.\n<br>Exponáty budou uvedeny vždy s vašim jménem.\n<br><br>&nbsp;- Máte firmu, která nám pomůže s drobnými stavebními pracemi? <br>Použijeme nějaký sádrokarton, budeme potřebovat nábytek, interaktivní prvky, osvětlovací techniku a mnoho dalších věcí. <br><br>Máte reklamku, tiskárnu, elektro? Chcete se účastnit?\n<br><br>&nbsp;- Jsme moc rádi za každý příspěvek, kterým jste nás kdy podpořili.\n<br>Každou korunu jsme vždy poctivě použili na rozvoj muzea. <br>Jen proto jsme tam, kde jsme, sbírka je takto rozsáhlá a naše cesta byla vždy zaměřena pro Váš zážitek.\n<br><br>&nbsp;Pokud byste nás chtěli finančně podpořit, budeme moc rádi.\n\nUmíme vystavit také darovací smlouvu a váš dar si můžete odepsat z daní.\n<br><br>&nbsp;- Také se poohlížíme po novém investorovi. <br>Zajímalo by Vás mít podíl v unikátním projektu muzea a obchodu?\n<br><br>&nbsp;- Pokud máte další nápady, jak tento unikátní projekt podpořit, budeme rádi, pokud se nám ozvete.\n<br><br>&nbsp;Jakoukoli pomoc prosím nabídněte na mail info@muzeum-meteoritu.cz, budeme vás zpětně kontaktovat.\n<br><br>&nbsp;Mockrát díky!!!</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--0 pt--50" name={"vgw62eqb9wk"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(144, 19, 254); font-weight: 700;\">Děkujeme za podporu!</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Slideshow name={"hqs7qxgxgw"} autoplay={true} infinite={true} transitionDuration={3}>
          
          <Column className="pb--60 pt--60">
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper >
              </ColumnWrapper>

              <ColumnWrapper >
                
                <Title className="title-box fs--20" content={"<span style=\"font-weight: bold;\"><a href=\"https://www.frydekmistek.cz/\" target=\"_blank\">&nbsp;Město Frýdek-Místek</a></span>"}>
                </Title>

                <Image style={{"maxWidth":246}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/d8d5a20f0bda46fbbcb3d2ac8dbdaebf_s=350x_.png 350w, https://cdn.swbpg.com/t/37955/d8d5a20f0bda46fbbcb3d2ac8dbdaebf_s=660x_.png 660w, https://cdn.swbpg.com/t/37955/d8d5a20f0bda46fbbcb3d2ac8dbdaebf_s=860x_.png 860w"} alt={""} src={"https://cdn.swbpg.com/t/37955/d8d5a20f0bda46fbbcb3d2ac8dbdaebf_s=660x_.png"} lightbox={false} use={"url"} href={"https://www.frydekmistek.cz/"} url={"https://www.frydekmistek.cz/"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Text className="text-box text-box--center" content={"<span style=\"font-weight: bold;\"><a href=\"https://www.avetour.cz/\" target=\"_blank\">AVETOUR - Nejlepší poznávací zájezdy</a></span>"}>
          </Text>

          <Image style={{"maxWidth":225}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/50a8766748ad4e72bda3aa7ddce2d3dc_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/37955/50a8766748ad4e72bda3aa7ddce2d3dc_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.avetour.cz/"} url={"https://www.avetour.cz/"}>
          </Image>

        </Slideshow>


        <Column className="--center pt--80" name={"n5xipmb0a1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"xro39m85vz"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--24" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Každá koruna nám pomáhá...</span>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":603}} content={"<span style=\"font-weight: bold;\">Pro příspěvek přímo na náš bankovní účet můžeš použít následující údaje:&nbsp;<br>Muzeum meteoritů s.r.o.&nbsp;<br>č. účtu:&nbsp;888777970/5500<br>Pro platby v CZK můžeš použít také tento QR kód:<br></span>"}>
              </Text>

              <Image style={{"maxWidth":241}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/968f7b2ec16e4a4cb43ea115df4ea2d6_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/37955/968f7b2ec16e4a4cb43ea115df4ea2d6_s=350x_.png"}>
              </Image>

              <Text className="text-box fs--14" content={"<span style=\"font-weight: bold;\">Pro platby v € prosím použij následující platební údaje:<br>IBAN:  CZ2055000000000888777970\n<br>SWIFT:  RZBCCZPP<br><br>MOC DĚKUJEME !!!<br><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s3" style={{"paddingTop":55,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} anim={"3"} name={"sq71x39thfc"} animS={"3"} layout={"l29"} lightbox={false}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":1000}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/2139c286973843eb82852912b8247fbd_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/37955/2139c286973843eb82852912b8247fbd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/2139c286973843eb82852912b8247fbd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/2139c286973843eb82852912b8247fbd_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/2139c286973843eb82852912b8247fbd_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37955/2139c286973843eb82852912b8247fbd_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":620}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/687673f8cd644519956211b27876955c_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/37955/687673f8cd644519956211b27876955c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/687673f8cd644519956211b27876955c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/687673f8cd644519956211b27876955c_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/d885e40ef67149f483de7cfbb9bbd95e_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/37955/d885e40ef67149f483de7cfbb9bbd95e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/d885e40ef67149f483de7cfbb9bbd95e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/d885e40ef67149f483de7cfbb9bbd95e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/d885e40ef67149f483de7cfbb9bbd95e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37955/d885e40ef67149f483de7cfbb9bbd95e_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/62710af38527434f872a0e73a26e3887_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/37955/62710af38527434f872a0e73a26e3887_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/62710af38527434f872a0e73a26e3887_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/62710af38527434f872a0e73a26e3887_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/62710af38527434f872a0e73a26e3887_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s3 --center" style={{"paddingTop":63,"paddingBottom":2}} anim={"3"} name={"ekd4moa2gds"} animS={"3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--20" content={"Muzeum Meteoritů s.r.o.&nbsp; © 2024  Vytvořeno s ❤️ k vesmíru"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}